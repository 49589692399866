/*
 * Import Fonts
 *
 * Font Name: Lato, Raleway
 */

/* Lato Hariline */
@font-face {
	font-family: 'Lato';
	font-weight: 100;
	src: url('../fonts/Lato-Hairline.ttf');
}

@font-face {
	font-family: 'Lato';
	font-weight: 100;
	font-style: italic;
	src: url('../fonts/Lato-HairlineItalic.ttf');
}
/* End Lato Hariline */


/* Lato Light */
@font-face {
	font-family: 'Lato';
	font-weight: 200;
	src: url('../fonts/Lato-Light.ttf');
}

@font-face {
	font-family: 'Lato';
	font-weight: 200;
	font-style: italic;
	src: url('../fonts/Lato-LightItalic.ttf');
}
/* End Lato Light */


/* Lato Regular */
@font-face {
	font-family: 'Lato';
	font-weight: 300;
	src: url('../fonts/Lato-Regular.ttf');
}

@font-face {
	font-family: 'Lato';
	font-weight: 300;
	font-style: italic;
	src: url('../fonts/Lato-Italic.ttf');
}
/* End Lato Regular */

/* Lato Bold */
@font-face {
	font-family: 'Lato';
	font-weight: 400;
	src: url('../fonts/Lato-Bold.ttf');
}

@font-face {
	font-family: 'Lato';
	font-weight: 400;
	font-style: italic;
	src: url('../fonts/Lato-BoldItalic.ttf');
}
/* End Lato Bold */

/* Lato Black */
@font-face {
	font-family: 'Lato';
	font-weight: 500;
	src: url('../fonts/Lato-Black.ttf');
}

@font-face {
	font-family: 'Lato';
	font-weight: 500;
	font-style: italic;
	src: url('../fonts/Lato-BlackItalic.ttf');
}
/* End Lato Black */

/* Raleway Thin */
@font-face {
	font-family: 'Raleway';
	font-weight: 100;
	src: url('../fonts/Raleway-Thin.ttf');
}

@font-face {
	font-family: 'Raleway';
	font-weight: 100;
	font-style: italic;
	src: url('../fonts/Raleway-ThinItalic.ttf');
}
/* End Raleway Thin */

/* Raleway ExtraLight */
@font-face {
	font-family: 'Raleway';
	font-weight: 200;
	src: url('../fonts/Raleway-ExtraLight.ttf');
}

@font-face {
	font-family: 'Raleway';
	font-weight: 200;
	font-style: italic;
	src: url('../fonts/Raleway-ExtraLightItalic.ttf');
}
/* End Raleway ExtraLight */

/* Raleway Light */
@font-face {
	font-family: 'Raleway';
	font-weight: 300;
	src: url('../fonts/Raleway-Light.ttf');
}

@font-face {
	font-family: 'Raleway';
	font-weight: 300;
	font-style: italic;
	src: url('../fonts/Raleway-LightItalic.ttf');
}
/* End Raleway Light */

/* Raleway Regular */
@font-face {
	font-family: 'Raleway';
	font-weight: 400;
	src: url('../fonts/Raleway-Regular.ttf');
}

@font-face {
	font-family: 'Raleway';
	font-weight: 400;
	font-style: italic;
	src: url('../fonts/Raleway-Italic.ttf');
}
/* End Raleway Regular */

/* Raleway Medium */
@font-face {
	font-family: 'Raleway';
	font-weight: 500;
	src: url('../fonts/Raleway-Medium.ttf');
}

@font-face {
	font-family: 'Raleway';
	font-weight: 500;
	font-style: italic;
	src: url('../fonts/Raleway-MediumItalic.ttf');
}
/* End Raleway Medium */

/* Raleway SemiBold */
@font-face {
	font-family: 'Raleway';
	font-weight: 600;
	src: url('../fonts/Raleway-SemiBold.ttf');
}

@font-face {
	font-family: 'Raleway';
	font-weight: 600;
	font-style: italic;
	src: url('../fonts/Raleway-SemiBoldItalic.ttf');
}
/* End Raleway SemiBold */

/* Raleway Bold */
@font-face {
	font-family: 'Raleway';
	font-weight: 700;
	src: url('../fonts/Raleway-Bold.ttf');
}

@font-face {
	font-family: 'Raleway';
	font-weight: 700;
	font-style: italic;
	src: url('../fonts/Raleway-BoldItalic.ttf');
}
/* End Raleway Bold */

/* Raleway ExtraBold */
@font-face {
	font-family: 'Raleway';
	font-weight: 800;
	src: url('../fonts/Raleway-ExtraBold.ttf');
}

@font-face {
	font-family: 'Raleway';
	font-weight: 800;
	font-style: italic;
	src: url('../fonts/Raleway-ExtraBoldItalic.ttf');
}
/* End Raleway ExtraBold */

/* Raleway Black */
@font-face {
	font-family: 'Raleway';
	font-weight: 900;
	src: url('../fonts/Raleway-Black.ttf');
}

@font-face {
	font-family: 'Raleway';
	font-weight: 900;
	font-style: italic;
	src: url('../fonts/Raleway-BlackItalic.ttf');
}
/* End Raleway Black */

/* Merriweather */
@font-face {
	font-family: 'Merriweather';
	font-weight: 300;
	src: url('../fonts/Merriweather-Light.ttf');
}

@font-face {
	font-family: 'Merriweather';
	font-weight: 300;
	font-style: italic;
	src: url('../fonts/Merriweather-LightItalic.ttf');
}

@font-face {
	font-family: 'Merriweather';
	font-weight: 400;
	src: url('../fonts/Merriweather-Regular.ttf');
}

@font-face {
	font-family: 'Merriweather';
	font-weight: 400;
	font-style: italic;
	src: url('../fonts/Merriweather-Italic.ttf');
}

@font-face {
	font-family: 'Merriweather';
	font-weight: 700;
	src: url('../fonts/Merriweather-Bold.ttf');
}

@font-face {
	font-family: 'Merriweather';
	font-weight: 700;
	font-style: italic;
	src: url('../fonts/Merriweather-BoldItalic.ttf');
}

@font-face {
	font-family: 'Merriweather';
	font-weight: 900;
	src: url('../fonts/Merriweather-Black.ttf');
}

/* End Merriweather */
