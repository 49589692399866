/*
 * Import Fonts
 *
 * Font Name: Lato, Raleway
 */
/* Lato Hariline */
@font-face {
  font-family: "Lato";
  font-weight: 100;
  src: url("../fonts/Lato-Hairline.ttf");
}
@font-face {
  font-family: "Lato";
  font-weight: 100;
  font-style: italic;
  src: url("../fonts/Lato-HairlineItalic.ttf");
}
/* End Lato Hariline */
/* Lato Light */
@font-face {
  font-family: "Lato";
  font-weight: 200;
  src: url("../fonts/Lato-Light.ttf");
}
@font-face {
  font-family: "Lato";
  font-weight: 200;
  font-style: italic;
  src: url("../fonts/Lato-LightItalic.ttf");
}
/* End Lato Light */
/* Lato Regular */
@font-face {
  font-family: "Lato";
  font-weight: 300;
  src: url("../fonts/Lato-Regular.ttf");
}
@font-face {
  font-family: "Lato";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/Lato-Italic.ttf");
}
/* End Lato Regular */
/* Lato Bold */
@font-face {
  font-family: "Lato";
  font-weight: 400;
  src: url("../fonts/Lato-Bold.ttf");
}
@font-face {
  font-family: "Lato";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/Lato-BoldItalic.ttf");
}
/* End Lato Bold */
/* Lato Black */
@font-face {
  font-family: "Lato";
  font-weight: 500;
  src: url("../fonts/Lato-Black.ttf");
}
@font-face {
  font-family: "Lato";
  font-weight: 500;
  font-style: italic;
  src: url("../fonts/Lato-BlackItalic.ttf");
}
/* End Lato Black */
/* Raleway Thin */
@font-face {
  font-family: "Raleway";
  font-weight: 100;
  src: url("../fonts/Raleway-Thin.ttf");
}
@font-face {
  font-family: "Raleway";
  font-weight: 100;
  font-style: italic;
  src: url("../fonts/Raleway-ThinItalic.ttf");
}
/* End Raleway Thin */
/* Raleway ExtraLight */
@font-face {
  font-family: "Raleway";
  font-weight: 200;
  src: url("../fonts/Raleway-ExtraLight.ttf");
}
@font-face {
  font-family: "Raleway";
  font-weight: 200;
  font-style: italic;
  src: url("../fonts/Raleway-ExtraLightItalic.ttf");
}
/* End Raleway ExtraLight */
/* Raleway Light */
@font-face {
  font-family: "Raleway";
  font-weight: 300;
  src: url("../fonts/Raleway-Light.ttf");
}
@font-face {
  font-family: "Raleway";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/Raleway-LightItalic.ttf");
}
/* End Raleway Light */
/* Raleway Regular */
@font-face {
  font-family: "Raleway";
  font-weight: 400;
  src: url("../fonts/Raleway-Regular.ttf");
}
@font-face {
  font-family: "Raleway";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/Raleway-Italic.ttf");
}
/* End Raleway Regular */
/* Raleway Medium */
@font-face {
  font-family: "Raleway";
  font-weight: 500;
  src: url("../fonts/Raleway-Medium.ttf");
}
@font-face {
  font-family: "Raleway";
  font-weight: 500;
  font-style: italic;
  src: url("../fonts/Raleway-MediumItalic.ttf");
}
/* End Raleway Medium */
/* Raleway SemiBold */
@font-face {
  font-family: "Raleway";
  font-weight: 600;
  src: url("../fonts/Raleway-SemiBold.ttf");
}
@font-face {
  font-family: "Raleway";
  font-weight: 600;
  font-style: italic;
  src: url("../fonts/Raleway-SemiBoldItalic.ttf");
}
/* End Raleway SemiBold */
/* Raleway Bold */
@font-face {
  font-family: "Raleway";
  font-weight: 700;
  src: url("../fonts/Raleway-Bold.ttf");
}
@font-face {
  font-family: "Raleway";
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/Raleway-BoldItalic.ttf");
}
/* End Raleway Bold */
/* Raleway ExtraBold */
@font-face {
  font-family: "Raleway";
  font-weight: 800;
  src: url("../fonts/Raleway-ExtraBold.ttf");
}
@font-face {
  font-family: "Raleway";
  font-weight: 800;
  font-style: italic;
  src: url("../fonts/Raleway-ExtraBoldItalic.ttf");
}
/* End Raleway ExtraBold */
/* Raleway Black */
@font-face {
  font-family: "Raleway";
  font-weight: 900;
  src: url("../fonts/Raleway-Black.ttf");
}
@font-face {
  font-family: "Raleway";
  font-weight: 900;
  font-style: italic;
  src: url("../fonts/Raleway-BlackItalic.ttf");
}
/* End Raleway Black */
/* Merriweather */
@font-face {
  font-family: "Merriweather";
  font-weight: 300;
  src: url("../fonts/Merriweather-Light.ttf");
}
@font-face {
  font-family: "Merriweather";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/Merriweather-LightItalic.ttf");
}
@font-face {
  font-family: "Merriweather";
  font-weight: 400;
  src: url("../fonts/Merriweather-Regular.ttf");
}
@font-face {
  font-family: "Merriweather";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/Merriweather-Italic.ttf");
}
@font-face {
  font-family: "Merriweather";
  font-weight: 700;
  src: url("../fonts/Merriweather-Bold.ttf");
}
@font-face {
  font-family: "Merriweather";
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/Merriweather-BoldItalic.ttf");
}
@font-face {
  font-family: "Merriweather";
  font-weight: 900;
  src: url("../fonts/Merriweather-Black.ttf");
}
/* End Merriweather */
/*
 * Variable
 */
/* Colors */
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  color: #191E21;
  font-size: 0.9em;
}

h1, h2, h3, h4, h5, h6,
.line div {
  font-weight: 800;
  font-family: "Raleway";
  color: #000;
  margin: 10px 0;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 26px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

.input-group .btn {
  height: 40px;
}

a {
  text-decoration: none;
}

a, .btn {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

a {
  color: #F73F52;
}
a:hover, a:focus {
  color: #C8081C;
}
a.facebook {
  background-color: #3b5998;
  color: #fff !important;
}
a.twitter {
  background-color: #4099FF;
  color: #fff !important;
}
a.youtube {
  background-color: #C4302B;
  color: #fff !important;
}
a.googleplus {
  background-color: #DE5245;
  color: #fff !important;
}
a.instagram {
  background-color: #885343;
  color: #fff !important;
}
a.tumblr {
  background-color: #36465D;
  color: #fff !important;
}
a.dribbble {
  background-color: #E14C86;
  color: #fff !important;
}
a.linkedin {
  background-color: #007BB6;
  color: #fff !important;
}
a.skype {
  background-color: #00AFF0;
  color: #fff !important;
}
a.rss {
  background-color: #ee802f;
  color: #fff !important;
}
a.github {
  background-color: #000;
  color: #fff !important;
}
a.whatsapp {
  background-color: #25D366;
  color: #fff !important;
}
a.snapchat {
  background-color: #FFFC01;
  color: #000 !important;
}
a.foursquare {
  background-color: #EF4B78;
  color: #fff !important;
}
a.pinterest {
  background-color: #B82B38;
  color: #fff !important;
}
a.wordpress {
  background-color: #21759A;
  color: #fff !important;
}
a.reddit {
  background-color: #1288DF;
  color: #fff !important;
}
a.hackernews {
  background-color: #FF6600;
  color: #fff !important;
}
a.designernews {
  background-color: #3275D6;
  color: #fff !important;
}
a.yahoo {
  background-color: #420297;
  color: #fff !important;
}
a.buffer {
  background-color: #000000;
  color: #fff !important;
}
a.vimeo {
  background-color: #1AB7EA;
  color: #fff !important;
}
a.twitch {
  background-color: #6441A4;
  color: #fff !important;
}
a.telegram {
  background-color: #3390EC;
  color: #fff !important;
}

.main-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  background-image: url("../images/spinner.svg");
  background-size: 100%;
  width: 80px;
  height: 80px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
  z-index: 999999;
}

.bold {
  font-weight: 600;
}

.spacing {
  margin: 10px 0 10px 0;
}

.font-lato {
  font-family: "Lato";
}

.font-raleway {
  font-family: "Raleway";
}

.font-merriweather {
  font-family: "Merriweather";
}

.badge-primary {
  background-color: #F73F52;
}

.badge-secondary {
  background-color: #C8081C;
}

.badge-warning {
  background-color: #F39C12;
}

.badge-danger {
  background-color: #E74C3C;
}

.badge-success {
  background-color: #1ABC9C;
}

.badge-info {
  background-color: #3498db;
}

.badge-grey {
  background-color: #E7E7E7;
}

.for-mobile, .for-tablet {
  display: none !important;
}

.breadcrumb {
  background-color: transparent;
  padding: 0;
  border: 0;
  margin: 0;
}
.breadcrumb li {
  font-size: 12px;
}
.breadcrumb li a {
  color: #F73F52;
}

.pagination li a {
  color: #333;
  border-radius: 50% !important;
  margin: 0 2px !important;
  border-color: transparent;
  background-color: #f2f2f2;
  width: 35px;
  height: 35px;
  line-height: 32px;
  text-align: center;
  padding: 0;
}
.pagination li a:focus, .pagination li a:active {
  outline: none !important;
  box-shadow: none;
}
.pagination li span {
  color: #333;
  border-radius: 50% !important;
  margin: 0 2px !important;
  border-color: transparent;
  background-color: #f2f2f2;
  width: 35px;
  height: 35px;
  line-height: 32px;
  text-align: center;
  padding: 0;
}
.pagination li.active a {
  color: #fff;
  transform: scale(1.16) translateX(-3px);
  -moz-transform: scale(1.16) translateX(-3px);
  -webkit-transform: scale(1.16) translateX(-3px);
  -o-transform: scale(1.16) translateX(-3px);
  margin-left: 8px !important;
  border-color: transparent;
  background-color: #F73F52;
}
.pagination li.active a:hover, .pagination li.active a:focus {
  border-color: transparent;
  background-color: #F73F52;
}
.pagination li.active span {
  color: #fff;
  transform: scale(1.16) translateX(-3px);
  -moz-transform: scale(1.16) translateX(-3px);
  -webkit-transform: scale(1.16) translateX(-3px);
  -o-transform: scale(1.16) translateX(-3px);
  margin-left: 8px !important;
  border-color: transparent;
  background-color: #F73F52;
}
.pagination li.active span:hover, .pagination li.active span:focus {
  border-color: transparent;
  background-color: #F73F52;
}

.page-item.active .page-link {
  border-color: transparent;
  background-color: #F73F52;
}

.page-link:hover {
  color: transparent;
}

.pagination-help-text {
  color: #666;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
}

.magz-list {
  padding-left: 18px;
}
.magz-list.square li {
  list-style: square;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 2000;
}

header.primary {
  position: fixed;
  z-index: 1999;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
}
header.primary.up {
  top: -172px;
}
header.primary.up nav.menu {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  border-top-color: transparent;
  border-bottom: none;
}
header.primary.up nav.menu .brand {
  display: block;
}
header.primary.up nav.menu ul.nav-list {
  margin-left: 40px;
}
header.primary.up nav.menu ul.nav-list li:first-child {
  margin-left: 0;
}
header.primary.up nav.menu ul:not(.dropdown-menu) > li > a {
  padding-top: 0;
  padding-bottom: 0;
  height: 53px;
  line-height: 52px;
}
header.primary.up nav.menu ul:not(.dropdown-menu) > li > a .badge {
  top: 2px;
}
header.primary.up nav.menu .dropdown-menu {
  top: 53px;
}
header.primary.up nav.menu .dropdown-menu .dropdown-menu {
  top: 0;
}

section {
  padding-top: 20px;
  padding-bottom: 40px;
}
section.first {
  padding-top: 240px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
}
section.most-popular {
  padding: 80px 0;
}
section.most-popular > .row {
  margin: 0;
  display: table;
}
section.most-popular > .row [class^=col-] {
  display: table-cell;
  float: none;
}
section.most-popular .image {
  background-size: cover;
  background-position: center;
}
section.most-popular .details {
  background-color: #FAFAFA;
}
section.most-popular .details .inner {
  padding: 80px 20px;
}
section.most-popular .details .inner .badge, section.most-popular .details .inner h2, section.most-popular .details .inner .detail, section.most-popular .details .inner p {
  margin-bottom: 20px;
}
section.most-popular .details .inner .badge {
  border: 2px solid #191E21;
  border-radius: 0;
  padding: 5px 10px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: transparent;
  color: #191E21;
}
section.most-popular .details .inner h2 {
  font-size: 30px;
  line-height: 38px;
}
section.most-popular .details .inner .detail {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
}
section.most-popular .details .inner .detail .time {
  margin-right: 10px;
}
section.most-popular .details .inner p {
  line-height: 26px;
  margin-bottom: 40px;
}
section.most-popular .details .inner .more {
  font-weight: 600;
  letter-spacing: 0.5px;
}
section.most-popular .details .inner .more div, section.most-popular .details .inner .more i {
  float: left;
}
section.most-popular .details .inner .more i {
  margin-left: 13px;
}
section.most-popular .details .inner .more i:before {
  font-size: 25px;
}
section.maps {
  padding-top: 60px;
  margin-bottom: -45px;
}
section.not-found {
  padding: 50px 0;
  text-align: center;
}
section.not-found .code {
  font-size: 12em;
  text-align: center;
  font-weight: 700;
  position: relative;
  display: inline-block;
}
section.not-found .code:after, section.not-found .code:before {
  position: absolute;
  right: -100px;
  top: 20px;
  width: 100px;
  height: 2px;
  background-color: #f2f2f2;
  transform: rotate(-30deg);
  content: " ";
}
section.not-found .code:after {
  bottom: 50px;
  top: initial;
  left: -120px;
  right: initial;
  transform: rotate(-30deg);
}
section.not-found h1 {
  font-size: 24px;
  line-height: 44px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0;
}
section.not-found p.lead {
  font-size: 16px;
  color: #999;
}
section.not-found .search-form {
  display: inline-block;
  margin: 0 auto;
}
section.not-found .search-form form {
  width: 300px;
  display: inline-block;
}
section.not-found .link {
  padding: 2px 0;
}
section.search .search-result {
  margin-top: 10px;
  margin-bottom: 30px;
  color: #999;
}

.form-control {
  border-radius: 0;
  height: 40px;
}
.form-control:focus {
  border-color: #989898;
  box-shadow: 0 1px 2px #ddd;
}

.btn {
  font-size: 15px;
  border-radius: 0;
  padding-left: 20px;
  padding-right: 20px;
  border-color: transparent;
  padding-top: 8px;
  padding-bottom: 8px;
  letter-spacing: 0.5px;
}
.btn.btn-lg {
  border-radius: 0;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.btn.btn-sm {
  border-radius: 0;
  padding: 5px 10px;
  font-size: 12px;
}
.btn:focus {
  outline: 0;
  box-shadow: none;
  border-color: transparent;
}
.btn.btn-rounded {
  border-radius: 3px;
}
.btn i {
  margin-right: 5px;
}

.btn-social {
  font-weight: 400;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn-social:hover {
  color: #fff;
  opacity: 0.8;
}

.btn-primary {
  background-color: #F73F52;
  border-color: #F73F52;
}
.btn-primary:disabled, .btn-primary.disabled, .btn-primary:hover, .btn-primary:active, .btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:focus {
  background-color: #c8081c;
  border-color: #c8081c;
  box-shadow: none;
  outline: 0;
}
.btn-primary:active:hover {
  background-color: #af0719;
}

.btn-success {
  background-color: #1ABC9C;
  color: #fff !important;
  border-color: #1ABC9C;
}
.btn-success:disabled, .btn-success.disabled, .btn-success:hover, .btn-success:active, .btn-success:active:hover, .btn-success:active:focus, .btn-success:focus {
  background-color: #0e6252;
  border-color: #0e6252;
  outline: 0;
  box-shadow: none;
}
.btn-success:active:hover {
  background-color: #0b4c3f;
}

.btn-warning {
  background-color: #F39C12;
  color: #fff !important;
  border-color: #F39C12;
}
.btn-warning:disabled, .btn-warning.disabled, .btn-warning:hover, .btn-warning:active, .btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:focus {
  background-color: #976008;
  border-color: #976008;
  outline: 0;
  box-shadow: none;
}
.btn-warning:active:hover {
  background-color: #7f5006;
}

.btn-danger {
  background-color: #E74C3C;
  color: #fff !important;
  border-color: #E74C3C;
}
.btn-danger:disabled, .btn-danger.disabled, .btn-danger:hover, .btn-danger:active, .btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:focus {
  background-color: #a82315;
  border-color: #a82315;
  outline: 0;
  box-shadow: none;
}
.btn-danger:active:hover {
  background-color: #921e12;
}

.btn-info {
  background-color: #3498db;
  color: #fff !important;
  border-color: #3498db;
}
.btn-info:disabled, .btn-info.disabled, .btn-info:hover, .btn-info:active, .btn-info:active:hover, .btn-info:active:focus, .btn-info:focus {
  background-color: #196090;
  border-color: #196090;
  outline: 0;
  box-shadow: none;
}
.btn-info:active:hover {
  background-color: #16527a;
}

.btn-default {
  background-color: #E7E7E7;
  color: #fff !important;
  border-color: #E7E7E7;
}
.btn-default:disabled, .btn-default.disabled, .btn-default:hover, .btn-default:active, .btn-default:active:hover, .btn-default:active:focus, .btn-default:focus {
  background-color: #b4b4b4;
  border-color: #b4b4b4;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}
.btn-default:active:hover {
  background-color: #a7a7a7;
}

.btn-magz {
  background-color: transparent;
  color: #F73F52;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  border-color: #F73F52;
  position: relative;
  overflow: hidden;
  z-index: 2;
}
.btn-magz:after {
  position: absolute;
  bottom: -20px;
  left: 20px;
  width: 5px;
  height: 5px;
  opacity: 0;
  border-radius: 50%;
  content: " ";
  background-color: #F73F52;
  z-index: -1;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.btn-magz:focus:after, .btn-magz:hover:after {
  transform: scale(200);
  -webkit-transform: scale(200);
  -moz-transform: scale(200);
  -o-transform: scale(200);
  opacity: 1;
  transition: all 1s;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
}
.btn-magz:focus {
  color: #fff;
}
.btn-magz:hover {
  background-color: transparent;
  color: #fff !important;
}
.btn-magz i {
  margin-left: 10px;
}
.btn-magz.white {
  color: #fff;
}
.btn-magz.white:after {
  background-color: #fff;
}
.btn-magz.white:focus, .btn-magz.white:hover {
  color: #F73F52 !important;
}

/* Social */
.social {
  padding: 0;
  margin: 0;
}
.social li {
  display: inline-block;
}
.social li a {
  display: block;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 5px;
  margin-bottom: 8px;
  position: relative;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.social li a svg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.social li a svg rect {
  width: inherit;
  height: inherit;
  fill: transparent;
  stroke: transparent;
  stroke-dasharray: 0;
  stroke-width: 3;
  transition: all 1.5s;
  -moz-transition: all 1.5s;
  -webkit-transition: all 1.5s;
  -o-transition: all 1.5s;
}
.social li a i:before {
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -o-transform: scale(1.3);
}
.social li a:first-child {
  margin-left: 0;
}
.social li a:hover {
  opacity: 0.8;
}
.social.trp li a:hover {
  background-color: transparent;
}
.social.trp li a:hover svg rect {
  stroke: #fff;
  stroke-dasharray: 200;
}
.social.trp li a.facebook:hover {
  color: #3b5998 !important;
}
.social.trp li a.facebook:hover svg rect {
  stroke: #3b5998;
}
.social.trp li a.twitter:hover {
  color: #4099FF !important;
}
.social.trp li a.twitter:hover svg rect {
  stroke: #4099FF;
}
.social.trp li a.youtube:hover {
  color: #C4302B !important;
}
.social.trp li a.youtube:hover svg rect {
  stroke: #C4302B;
}
.social.trp li a.googleplus:hover {
  color: #DE5245 !important;
}
.social.trp li a.googleplus:hover svg rect {
  stroke: #DE5245;
}
.social.trp li a.instagram:hover {
  color: #885343 !important;
}
.social.trp li a.instagram:hover svg rect {
  stroke: #885343;
}
.social.trp li a.tumblr:hover {
  color: #36465D !important;
}
.social.trp li a.tumblr:hover svg rect {
  stroke: #36465D;
}
.social.trp li a.dribbble:hover {
  color: #E14C86 !important;
}
.social.trp li a.dribbble:hover svg rect {
  stroke: #E14C86;
}
.social.trp li a.linkedin:hover {
  color: #007BB6 !important;
}
.social.trp li a.linkedin:hover svg rect {
  stroke: #007BB6;
}
.social.trp li a.skype:hover {
  color: #00AFF0 !important;
}
.social.trp li a.skype:hover svg rect {
  stroke: #00AFF0;
}
.social.trp li a.rss:hover {
  color: #ee802f !important;
}
.social.trp li a.rss:hover svg rect {
  stroke: #ee802f;
}
.social.trp li a.github:hover {
  color: #000 !important;
}
.social.trp li a.github:hover svg rect {
  stroke: #000;
}
.social.trp li a.whatsapp:hover {
  color: #25D366 !important;
}
.social.trp li a.whatsapp:hover svg rect {
  stroke: #25D366;
}
.social.trp li a.snapchat:hover {
  color: #FFFC01 !important;
}
.social.trp li a.snapchat:hover svg rect {
  stroke: #FFFC01;
}
.social.trp li a.foursquare:hover {
  color: #EF4B78 !important;
}
.social.trp li a.foursquare:hover svg rect {
  stroke: #EF4B78;
}
.social.trp li a.pinterest:hover {
  color: #B82B38 !important;
}
.social.trp li a.pinterest:hover svg rect {
  stroke: #B82B38;
}
.social.trp li a.wordpress:hover {
  color: #21759A !important;
}
.social.trp li a.wordpress:hover svg rect {
  stroke: #21759A;
}
.social.trp li a.reddit:hover {
  color: #1288DF !important;
}
.social.trp li a.reddit:hover svg rect {
  stroke: #1288DF;
}
.social.trp li a.hackernews:hover {
  color: #FF6600 !important;
}
.social.trp li a.hackernews:hover svg rect {
  stroke: #FF6600;
}
.social.trp li a.designernews:hover {
  color: #3275D6 !important;
}
.social.trp li a.designernews:hover svg rect {
  stroke: #3275D6;
}
.social.trp li a.yahoo:hover {
  color: #420297 !important;
}
.social.trp li a.yahoo:hover svg rect {
  stroke: #420297;
}
.social.trp li a.buffer:hover {
  color: #000000 !important;
}
.social.trp li a.buffer:hover svg rect {
  stroke: #000000;
}
.social.trp li a.vimeo:hover {
  color: #1AB7EA !important;
}
.social.trp li a.vimeo:hover svg rect {
  stroke: #1AB7EA;
}
.social.trp li a.twitch:hover {
  color: #6441A4 !important;
}
.social.trp li a.twitch:hover svg rect {
  stroke: #6441A4;
}
.social.trp li a.telegram:hover {
  color: #3390EC !important;
}
.social.trp li a.telegram:hover svg rect {
  stroke: #3390EC;
}

.form-group label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
}
.form-group label.fw {
  display: block;
}
.form-group label .required {
  color: #E74C3C;
  font-weight: 600;
  font-size: 16px;
}
.form-group label .required:before {
  content: "*";
}
.form-group .help-block {
  font-size: 12px;
  margin-top: -3px;
}
.form-group .form-control {
  border-radius: 0;
  font-family: "Lato";
  font-weight: 300;
}
.form-group .form-control:focus {
  border-color: #989898;
  box-shadow: 0 1px 2px #ddd;
}
.form-group .form-control.rounded {
  border-radius: 4px;
}
.form-group.has-error .form-control {
  border-color: #E74C3C;
}
.form-group.has-error .control-label {
  color: #E74C3C;
}
.form-group.has-error .help-block {
  color: #E74C3C;
}
.form-group.has-success .form-control {
  border-color: #1ABC9C;
}
.form-group.has-success .control-label {
  color: #1ABC9C;
}
.form-group.has-success .help-block {
  color: #1ABC9C;
}
.form-group.has-warning .form-control {
  border-color: #F39C12;
}
.form-group.has-warning .control-label {
  color: #F39C12;
}
.form-group.has-warning .help-block {
  color: #F39C12;
}
.form-group.floating {
  position: relative;
  margin-top: 10px;
}
.form-group.floating .form-control {
  padding-left: 0;
  padding-right: 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-width: 2px;
  box-shadow: none;
}
.form-group.floating.stacked label {
  font-size: 12px;
  position: absolute;
  top: -13px;
  left: 0;
  background-color: #fff;
}
.form-group.floating.focus label {
  position: absolute;
  top: 10px;
  left: 0;
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  z-index: -1;
}
.form-group.floating.focus .form-control {
  background-color: transparent;
  border-radius: 0;
}
.form-group.floating.focus .form-control::-webkit-input-placeholder {
  opacity: 0;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.form-group.floating.focus.focused label {
  top: -13px;
  left: 0;
  font-size: 12px;
}
.form-group.floating.focus.focused .form-control::-webkit-input-placeholder {
  opacity: 1;
}

.firstbar {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  background-color: #FFFFFF;
}
.firstbar .brand {
  float: left;
  margin: 20px 0;
}
.firstbar .brand a {
  text-decoration: none;
}
.firstbar .brand img {
  width: 200px;
  display: block;
  float: left;
}
.firstbar .brand .text {
  font-size: 6em;
  text-transform: uppercase;
  letter-spacing: 8px;
  float: none;
  font-weight: 100;
  margin-top: -25px;
  margin-bottom: -20px;
}
.firstbar .search {
  margin-top: 20px;
  margin-left: 55px;
}
.firstbar .search .form-group {
  margin-bottom: 0;
}
.firstbar .search .form-group .input-group-btn .btn {
  line-height: 0;
}
.firstbar .search .form-text div {
  text-transform: uppercase;
  float: left;
  font-weight: 500;
  font-size: 11px;
  margin-right: 10px;
  color: #F73F52;
}
.firstbar .search .form-text ul {
  margin: 0;
  padding: 0;
}
.firstbar .search .form-text ul li {
  float: left;
  padding: 0 8px 0 0;
  display: block;
  font-size: 11px;
  font-weight: 400;
}
.firstbar .search .form-text ul li a {
  color: #191E21;
}
.firstbar .nav-icons {
  float: right;
  padding: 0;
  margin: 0;
  margin-top: 27px;
}
.firstbar .nav-icons li {
  display: block;
  float: left;
  margin-left: 20px;
}
.firstbar .nav-icons li a {
  display: inline-block;
  color: #191E21;
}
.firstbar .nav-icons li a i {
  font-size: 18px;
  float: left;
  width: 25px;
  text-align: left;
}
.firstbar .nav-icons li a div {
  float: left;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: 3px;
}
.firstbar .nav-icons li a:hover {
  color: #F73F52;
}

.headline {
  margin-bottom: 20px;
}
.headline .item {
  padding: 5px;
  width: 100%;
}
.headline .item a {
  color: #191E21;
  font-size: 14px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
}
.headline .item a .badge {
  background-color: #F73F52;
  margin-right: 5px;
}
.headline .nav {
  display: inline-block;
  float: left;
  margin-right: 20px;
  position: relative;
  z-index: 2;
}
.headline .carousel-control {
  text-shadow: none;
  background-color: transparent;
  background-image: none;
  position: relative;
  display: inline-block;
  color: #000;
  width: auto;
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  margin-right: -5px;
  font-size: 20px;
  line-height: 24px;
}
.headline .carousel-control.right {
  border-left: 0;
}
.headline .carousel-inner {
  overflow: hidden;
  width: initial;
}

.article p, .article ul, article p, article ul, .most-popular p, .most-popular ul {
  font-family: "Merriweather", sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
}
.article .time, .article .category, .article .view, article .time, article .category, article .view, .most-popular .time, .most-popular .category, .most-popular .view {
  font-size: 10px;
  letter-spacing: 0.5px;
  font-weight: 500;
  text-transform: uppercase;
}

.article {
  margin-bottom: 35px;
}

article figure {
  height: auto;
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
  display: inline-block;
}
article figure img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  vertical-align: middle;
  object-position: center;
}
article .padding {
  padding: 15px;
  padding-top: 0;
}
article .detail {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
}

.article .inner {
  box-shadow: 0 1px 2px #ddd;
}
.article .padding {
  padding: 15px;
  padding-top: 0;
}
.article h2 {
  text-transform: capitalize;
  font-size: 20px;
  margin: 0;
  line-height: 28px;
  margin-bottom: 10px;
}
.article h2 a {
  color: #333;
  text-decoration: none;
}
.article h2 a:hover {
  color: #F73F52;
}
.article p {
  line-height: 24px;
  font-size: 14px;
}

article .detail {
  display: flex;
  margin-bottom: 5px;
  font-size: 12px;
}
article .time, article .category {
  margin: 0 10px;
  color: #191E21;
}
article .category {
  margin: 0 10px;
}
article .category a {
  color: #F73F52;
}
article .time {
  margin: 0;
}
article footer {
  text-align: right;
}
article footer .btn-primary {
  background-color: transparent;
  border-color: #F73F52;
  color: #F73F52;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  overflow: hidden;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
article footer .btn-primary:after {
  position: absolute;
  content: " ";
  bottom: 0;
  left: 20px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  z-index: -1;
}
article footer .btn-primary:hover {
  color: #fff;
}
article footer .btn-primary:hover:after {
  transform: scale(20);
  -moz-transform: scale(20);
  -webkit-transform: scale(20);
  -o-transform: scale(20);
  background-color: #F73F52;
}
article footer .btn-primary:focus {
  outline: 0;
}

.more {
  overflow: hidden;
  height: 43px;
}
.more div {
  transition: all 1s;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
}
.more div:last-child {
  opacity: 0;
}
.more div i:before {
  font-size: 30px;
}
.more:hover div:last-child {
  opacity: 1;
}
.more:hover div:first-child {
  margin-top: -43px;
  margin-bottom: 20px;
}

.love {
  color: #989898;
  float: left;
  margin-top: 10px;
  position: relative;
}
.love i {
  display: inline-block;
  float: left;
  margin-top: 1px;
}
.love i:before {
  font-size: 28px;
}
.love div {
  display: inline-block;
  margin-left: 60px;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 5px;
}
.love.active {
  color: #F73F52;
}
.love i.animated {
  animation-name: love;
  -webkit-animation-name: love;
  -moz-animation-name: love;
  -o-animation-name: love;
  animation-fill-mode: backwards;
  -moz-animation-fill-mode: backwards;
  -o-animation-fill-mode: backwards;
  -webkit-animation-fill-mode: backwards;
  animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  position: absolute;
  left: 0;
  top: 0;
}

@keyframes love {
  0% {
    top: 0px;
    transform: rotate(0);
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
  }
  20% {
    top: -10px;
    transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -webkit-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    opacity: 0.8;
  }
  40% {
    top: -20px;
    transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    -webkit-transform: rotate(-20deg);
    -o-transform: rotate(-20deg);
    opacity: 0.6;
  }
  60% {
    top: -30px;
    transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    opacity: 0.4;
  }
  80% {
    top: -40px;
    transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    opacity: 0.2;
  }
  100% {
    top: -50px;
    transform: rotate(0);
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    opacity: 0;
  }
}
@-webkit-keyframes love {
  0% {
    top: 0px;
    transform: rotate(0);
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
  }
  20% {
    top: -10px;
    transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -webkit-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    opacity: 0.8;
  }
  40% {
    top: -20px;
    transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    -webkit-transform: rotate(-20deg);
    -o-transform: rotate(-20deg);
    opacity: 0.6;
  }
  60% {
    top: -30px;
    transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    opacity: 0.4;
  }
  80% {
    top: -40px;
    transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    opacity: 0.2;
  }
  100% {
    top: -50px;
    transform: rotate(0);
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    opacity: 0;
  }
}
.best-of-the-week {
  margin-top: 20px;
}
.best-of-the-week h1 {
  font-size: 16px;
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  color: #191E21;
}
.best-of-the-week h1 div.text {
  background-color: #fff;
  display: inline-block;
  padding: 10px;
  padding-right: 20px;
  padding-left: 0;
}
.best-of-the-week h1:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  background-color: #ddd;
  z-index: -1;
}
.best-of-the-week h1 .carousel-nav .next, .best-of-the-week h1 .carousel-nav .prev {
  width: 35px;
  height: 35px;
  background-color: #fff;
  line-height: 34px;
}
.best-of-the-week .article figure {
  height: 175px;
}
.best-of-the-week .article h2 {
  font-size: 16px;
  margin: 0 0 5px 0;
}
.best-of-the-week .article h2 a {
  color: #333;
}
.best-of-the-week .article h2 a:hover {
  color: #F73F52;
}

.featured {
  display: inline-block;
  width: 100%;
  position: relative;
  height: 550px;
  margin-bottom: 20px;
  overflow: hidden;
}
.featured .inner {
  padding: 20px;
}
.featured figure {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.featured .overlay {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: transparent;
  background-image: linear-gradient(transparent 0%, transparent 50%, rgba(0, 0, 0, 0.1) 56%, rgba(0, 0, 0, 0.6) 93%, rgba(0, 0, 0, 0.59) 100%);
  background-image: -webkit-linear-gradient(transparent 0%, transparent 50%, rgba(0, 0, 0, 0.1) 56%, rgba(0, 0, 0, 0.6) 93%, rgba(0, 0, 0, 0.59) 100%);
}
.featured .details {
  padding: 20px 30px;
  position: absolute;
  bottom: 10px;
}
.featured .details h1, .featured .details .category, .featured .details .time {
  color: #fff;
  margin: 0;
}
.featured .details .category {
  margin-bottom: 10px;
}
.featured .details .category a {
  font-size: 12px;
  font-weight: 400;
  padding: 5px 15px;
  display: inline-block;
  border-radius: 30px;
  background-color: #fff;
  color: #000;
  text-decoration: none;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.featured .details .category a:hover {
  opacity: 0.8;
}
.featured .details h1 {
  margin-bottom: 5px;
  text-transform: capitalize;
  line-height: 1.4em;
  font-size: 28px;
  font-weight: 700;
}
.featured .details h1 a {
  text-decoration: none;
  color: #fff;
}

.topbar {
  position: relative;
  display: inline-block;
  width: 100%;
}
.topbar .inner {
  border-bottom: 1px solid #ddd;
  display: inline-block;
  width: 100%;
}
.topbar .right {
  float: right;
}
.topbar .right ul li:last-child a {
  margin-right: 0;
}
.topbar .left {
  float: left;
}
.topbar .left ul li:first-child a {
  margin-left: 0;
}
.topbar ul {
  padding: 0;
  margin: 0;
  margin-bottom: -5px;
}
.topbar ul.info {
  margin-left: 4px;
}
.topbar ul.info li a {
  letter-spacing: 0.3px;
}
.topbar ul.info li a i {
  display: inline-block;
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  -moz-transform: scale(1.5);
  margin-right: 5px;
}
.topbar ul li {
  float: left;
  display: block;
}
.topbar ul li a {
  color: #191E21;
  margin: 0 7px;
  padding: 10px 0;
  display: block;
  text-decoration: none;
  position: relative;
  z-index: 1;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
}
.topbar ul li a i {
  display: none;
}
.topbar ul li a:hover {
  color: #F73F52;
}
.topbar ul li.active a:after, .topbar ul li a:hover:after {
  height: 100%;
}

.menu {
  position: relative;
  display: inline-block;
  height: 55px;
  width: 100%;
  line-height: 23px;
  background-color: #FFFFFF;
  border-top: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD;
}
.menu .container {
  /* Mega menu support */
  position: relative;
}
.menu .brand {
  display: none;
  float: left;
  width: 100px;
  height: 55px;
  line-height: 52px;
}
.menu .brand img {
  width: 100%;
}
.menu .brand a {
  text-decoration: none;
}
.menu .mobile-toggle {
  font-size: 32px;
  line-height: 0;
  float: right;
  margin-left: 20px;
  display: none;
  padding: 11px 0;
}
.menu ul.nav-list > li:first-child {
  margin-left: -17px;
}
.menu ul {
  margin: 0 auto;
  display: inline-block;
  padding: 0;
  z-index: 9;
}
.menu ul > li {
  float: left;
  display: block;
}
.menu ul > li.magz-dropdown-megamenu {
  position: static;
}
.menu ul > li > a {
  display: block;
  color: #191E21;
  padding: 11px 17px;
  text-decoration: none;
  letter-spacing: 0.5px;
  font-weight: 700;
  line-height: 32px;
  font-family: "Raleway", sans-serif;
  position: relative;
  z-index: 1;
}
.menu ul > li > a i {
  margin-left: 5px;
}
.menu ul > li > a .badge {
  background-color: #F73F52;
  position: absolute;
  top: 2px;
  right: -5px;
}
.menu ul > li > a:after {
  content: " ";
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #F73F52;
  transition: all 1s;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  margin-top: -2px;
}
.menu ul > li.active a:after, .menu ul > li:hover > a:after {
  width: 100%;
}
.menu ul > li:hover > .dropdown-menu {
  visibility: visible;
  opacity: 1;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
}
.menu ul > li .dropdown-menu {
  border-radius: 0;
  border: none;
  top: 54px;
  box-shadow: 0 20px 60px #ddd;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.5);
  -moz-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -o-transform: scale(0.5);
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
}
.menu ul > li .dropdown-menu.megamenu {
  display: block;
  margin: 0;
  left: 15px;
  right: 15px;
  z-index: 1999;
  background-color: #fff;
}
.menu ul > li .dropdown-menu.megamenu .megamenu-inner {
  padding: 20px;
  padding-bottom: 15px;
}
.menu ul > li .dropdown-menu.megamenu .megamenu-title {
  font-size: 16px;
  position: relative;
  font-weight: 800;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.menu ul > li .dropdown-menu.megamenu .megamenu-title .all {
  position: absolute;
  right: 0;
  font-size: 12px;
  font-weight: 700;
  color: #383838;
  top: 5px;
}
.menu ul > li .dropdown-menu.megamenu .megamenu-title .all i {
  color: #F73F52;
  margin-left: 5px;
}
.menu ul > li .dropdown-menu.megamenu .vertical-menu {
  width: 100%;
}
.menu ul > li .dropdown-menu.megamenu .vertical-menu li {
  display: inline-block;
  width: 100%;
  float: none;
}
.menu ul > li .dropdown-menu.megamenu .vertical-menu li a {
  padding: 5px 0;
  height: auto;
  line-height: 34px;
  border-bottom: 1px solid #f2f2f2;
}
.menu ul > li .dropdown-menu.megamenu .vertical-menu li a i {
  margin-left: 0;
  width: 20px;
  float: left;
}
.menu ul > li .dropdown-menu.megamenu .vertical-menu li a:after {
  top: 100%;
}
.menu ul > li .dropdown-menu.megamenu article {
  margin-bottom: 0;
}
.menu ul > li .dropdown-menu.megamenu article .inner {
  box-shadow: none;
}
.menu ul > li .dropdown-menu.megamenu article figure {
  height: 170px;
  margin-bottom: 0;
}
.menu ul > li .dropdown-menu.megamenu article .padding {
  padding: 0;
}
.menu ul > li .dropdown-menu.megamenu article .padding h2 {
  font-size: 16px;
  line-height: 24px;
}
.menu ul > li .dropdown-menu > li {
  float: none;
  display: block;
  position: relative;
}
.menu ul > li .dropdown-menu > li.divider {
  padding: 10px 0;
  background-color: #fff;
  position: relative;
}
.menu ul > li .dropdown-menu > li.divider:before {
  content: " ";
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #f2f2f2;
}
.menu ul > li .dropdown-menu > li > a {
  background-color: #fff;
  width: 230px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  padding: 7px 20px;
}
.menu ul > li .dropdown-menu > li > a:after {
  position: absolute;
  content: " ";
  top: 100%;
  left: 50px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  background-color: transparent;
  z-index: -1;
}
.menu ul > li .dropdown-menu > li > a i {
  float: right;
}
.menu ul > li .dropdown-menu > li > a .icon {
  float: none;
  width: 25px;
  float: left;
  margin-left: -2px;
}
.menu ul > li .dropdown-menu > li.active a:after {
  transition: all 1s;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transform: scale(82);
  -webkit-transform: scale(82);
  -moz-transform: scale(82);
  -o-transform: scale(82);
  background-color: #F73F52;
  width: 5px;
}
.menu ul > li .dropdown-menu > li:hover > a {
  color: #fff;
}
.menu ul > li .dropdown-menu > li:hover > a:after {
  transition: all 1s;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transform: scale(82);
  -webkit-transform: scale(82);
  -moz-transform: scale(82);
  -o-transform: scale(82);
  background-color: #F73F52;
  width: 5px;
}
.menu ul > li .dropdown-menu > li ul {
  position: absolute;
  left: 230px;
  top: 0;
}

section .container {
  padding-left: 15px;
  padding-right: 15px;
}

.newsletter {
  padding: 20px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  font-size: 14px;
  color: #989898;
}
.newsletter .icon {
  text-align: center;
  margin-bottom: 20px;
}
.newsletter .icon i:before {
  font-size: 9em;
}
.newsletter .icon.spin h1 {
  margin-top: 0;
}
.newsletter .icon.spin i:before {
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.newsletter .icon.success h1 {
  margin-top: 0;
  color: #1ABC9C;
}
.newsletter .icon.success i:before {
  color: #1ABC9C;
}
.newsletter .icon.error h1 {
  margin-top: 0;
  color: #F73F52;
}
.newsletter .icon.error i:before {
  color: #F73F52;
}
.newsletter .icon h1 {
  margin: 0;
  font-size: 24px;
  margin-top: -20px;
  color: #989898;
}
.newsletter p {
  margin-top: 10px;
  line-height: 22px;
}
.newsletter .form-control {
  font-size: 14px;
  height: 40px;
}
.newsletter .form-control:focus {
  box-shadow: none;
}
.newsletter .form-control.email {
  border: 0;
}
.newsletter .btn i:before {
  font-size: 18px;
}

.article-list {
  margin-bottom: 50px;
  position: relative;
}
.article-list:last-child {
  margin-bottom: 0;
}
.article-list .badge {
  position: absolute;
  top: 0;
  right: 15px;
  border-radius: 0;
  background-color: #F73F52;
}
.article-list .inner {
  display: inline-block;
  width: 100%;
}
.article-list figure {
  width: 300px;
  height: 195px;
  overflow: hidden;
  background-color: #000;
  float: left;
  display: inline-block;
  margin-bottom: 0;
}
.article-list figure img {
  width: 100%;
}
.article-list .details {
  padding: 5px;
  margin-left: 315px;
}
.article-list .details .detail .category, .article-list .details .detail .time {
  margin: 0 10px;
}
.article-list .details .detail .category {
  margin: 0;
}
.article-list .details h1 {
  margin: 0;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 28px;
}
.article-list .details h1 a {
  color: #000;
  text-decoration: none;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.article-list .details h1 a:hover {
  color: #F73F52;
}
.article-list .details p {
  line-height: 26px;
}
.article-list footer {
  margin-top: 0;
}

.line {
  display: inline-block;
  width: 100%;
  height: 4px;
  background-color: #f2f2f2;
  margin: 30px 0;
  position: relative;
}
.line.thin {
  height: 2px;
}
.line.transparent {
  background-color: transparent;
}
.line.little {
  margin: 10px 0;
}
.line.top {
  margin-top: 50px;
}
.line div {
  margin: 0;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  font-size: 16px;
  background-color: #fff;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
  color: #000;
}

.title-col {
  font-size: 18px;
  position: relative;
  margin-top: 0;
}
.title-col:after {
  content: " ";
  position: absolute;
  bottom: -10px;
  width: 15px;
  background-color: #F73F52;
  height: 3px;
  left: 0;
}

.body-col {
  margin-top: 30px;
}

.article-mini {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
}
.article-mini .inner {
  display: inline-block;
  height: 100%;
}
.article-mini .padding {
  padding: 0 10px;
  margin-left: 85px;
}
.article-mini figure {
  float: left;
  display: inline-block;
  width: 80px;
  height: 60px;
  overflow: hidden;
  margin: 0;
}
.article-mini figure img {
  width: 100%;
}
.article-mini h1 {
  margin: 0;
  line-height: 22px;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 14px;
}
.article-mini h1 a {
  color: #000;
}
.article-mini .detail {
  margin-top: 5px;
}
.article-mini .detail .category {
  margin-left: 0;
}
.article-mini .detail .category a {
  text-transform: uppercase;
  color: #F73F52;
}

.tags-list {
  margin: 0;
  padding: 0;
  padding-left: 15px;
}
.tags-list li {
  padding: 5px;
}
.tags-list li a {
  color: #000;
  position: relative;
  text-decoration: none;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.tags-list li a:hover {
  opacity: 0.8;
}
.tags-list li a:before {
  content: "#";
}
.tags-list li a:after {
  position: absolute;
  content: " ";
  left: 0;
  bottom: -1px;
  width: 0;
  height: 1px;
  background-color: #F73F52;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.tags-list li a:hover:after {
  width: 100%;
}

aside {
  margin-bottom: 40px;
  display: inline-block;
  width: 100%;
}
aside:last-child {
  margin-bottom: 0;
}
aside .aside-title {
  font-size: 16px;
  position: relative;
  font-weight: 800;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 30px;
}
aside .aside-title .all {
  position: absolute;
  right: 0;
  font-size: 12px;
  font-weight: 700;
  color: #383838;
  top: 5px;
}
aside .aside-title .all i {
  color: #F73F52;
  margin-left: 5px;
}
aside .aside-title:after {
  content: " ";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 15px;
  height: 2px;
  background-color: #F73F52;
}
aside .article-mini {
  margin-bottom: 10px;
  padding-bottom: 10px;
}
aside .article-mini figure {
  margin-bottom: 0;
}
aside .article-mini h1 {
  margin-top: -3px;
}
aside .nav li button {
  background-color: #F0F0F0;
  color: #333;
  padding: 12px;
  font-size: 14px;
  border-radius: 0 !important;
}
aside .nav li button i:before {
  font-size: 18px;
  margin-right: 5px;
}
aside .nav li button.active {
  background-color: #fff;
  color: #000;
  border-top: 2px solid #F73F52 !important;
}
aside .nav li button.active i {
  color: #F73F52;
}
aside .tab-pane {
  border: 1px solid #ddd;
  border-top: none;
  padding: 20px;
}
aside .line {
  margin: 40px 0;
}
aside .sponsored {
  padding: 0;
  margin: 0 -5px;
}
aside .sponsored li {
  float: left;
  display: inline-block;
  width: 50%;
  padding: 5px;
}
aside .sponsored li a img {
  width: 100%;
}
aside .ads img {
  width: 100%;
}
aside .ads figcaption {
  font-size: 12px;
  margin-top: 3px;
  color: #989898;
}

.nav-link:focus, .nav-link:hover {
  color: #F73F52;
}

@keyframes loading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.article-fw {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}
.article-fw:first-child {
  margin-bottom: 0;
}
.article-fw figure {
  overflow: hidden;
  width: 100%;
  height: 178px;
}
.article-fw figure img {
  width: 100%;
}
.article-fw .details h1 {
  margin: 0;
  font-size: 24px;
  line-height: 34px;
}
.article-fw .details h1 a {
  color: #000;
  text-decoration: none;
}
.article-fw .details h1 a:hover {
  color: #F73F52;
}
.article-fw .details p {
  margin-top: 15px;
  line-height: 24px;
}

.comments .media-left {
  width: 70px;
  height: 70px;
  overflow: hidden;
}
.comments .media-body p {
  font-size: 14px;
  line-height: 22px;
}
.comments .detail {
  display: flex;
  font-size: 14px;
}
.comments .detail .time {
  display: inline-block;
  margin: 0 5px;
}
.comments .detail .link {
  display: inline-block;
  margin: 0 5px;
}
.comments .detail .link a {
  color: #F73F52;
}
.comments .detail .time {
  color: #989898;
}
.comments .detail .divider {
  height: 4px;
  width: 4px;
  background-color: #ddd;
  margin-top: 8px;
  margin-left: 5px;
  margin-right: 5px;
}

.carousel#featured .carousel-control {
  background-color: transparent;
  background-image: none;
  bottom: initial;
  width: auto;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  left: initial;
  right: initial;
  opacity: 0;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.carousel#featured .carousel-control span:before {
  font-size: 50px;
}
.carousel#featured .carousel-control.left {
  left: 0;
  right: initial;
}
.carousel#featured:hover .carousel-control.left {
  left: 20px;
  right: initial;
}
.carousel#featured .carousel-control.right {
  right: 0;
  left: initial;
}
.carousel#featured:hover .carousel-control {
  opacity: 1;
}
.carousel#featured:hover .carousel-control.right {
  right: 20px;
  left: initial;
}

.box-wrapper {
  width: 400px;
  margin: 0 auto;
}

.box {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
}
.box.box-border {
  box-shadow: none;
  border: 1px solid #f2f2f2;
}
.box .box-body {
  padding: 30px;
}
.box .box-body h4 {
  margin-bottom: 30px;
}

.title-line {
  display: table;
  width: 100%;
  padding: 20px 0;
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  color: #ddd;
}
.title-line:before, .title-line:after {
  border-top: 2px dashed #eee;
  content: "";
  display: table-cell;
  position: relative;
  top: 0.5em;
  width: 45%;
}
.title-line:before {
  right: 1.5%;
}
.title-line:after {
  left: 1.5%;
}

.with-sidebar {
  display: inline-block;
  width: 100%;
}
.with-sidebar .sidebar.sidebar-sticky {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 250px;
  z-index: 2000;
  background-color: #fff;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
}
.with-sidebar .sidebar .sidebar-brand {
  width: 100%;
  padding: 50px;
}
.with-sidebar .sidebar .sidebar-brand img {
  width: 100%;
}
.with-sidebar .sidebar .sidebar-nav ul {
  padding: 0;
  margin: 0;
}
.with-sidebar .sidebar .sidebar-nav ul li {
  display: inline-block;
  width: 100%;
}
.with-sidebar .sidebar .sidebar-nav ul li a {
  display: block;
  padding: 13px 30px;
  text-decoration: none;
  color: #000;
}
.with-sidebar .sidebar .sidebar-nav ul li.active a, .with-sidebar .sidebar .sidebar-nav ul li a:hover, .with-sidebar .sidebar .sidebar-nav ul li:focus {
  background-color: #F73F52;
  color: #fff;
}
.with-sidebar .has-sidebar {
  float: left;
  padding-left: 280px;
  padding-right: 20px;
  display: inline-block;
  width: 100%;
}
.with-sidebar footer {
  padding: 15px 0;
  border-top: 1px solid #f9f9f9;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  color: #29292b;
}

.faq {
  display: inline-block;
  width: 100%;
  margin-bottom: 40px;
}
.faq:last-child {
  margin-bottom: 0;
}
.faq .faq-title h2 {
  font-size: 22px;
  margin: 0;
}
.faq .faq-body {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .with-sidebar .sidebar {
    display: none;
  }
  .with-sidebar .has-sidebar {
    padding: 0 20px;
  }
  .for-tablet {
    display: block !important;
  }
  footer.footer .block {
    margin-bottom: 30px;
  }
  footer.footer .line {
    margin: 0;
    margin-bottom: 30px;
  }
  .divider-top {
    margin-top: 50px;
  }
  .sidebar {
    position: fixed;
    top: 0;
    right: -320px;
    height: 100%;
    width: 320px;
    z-index: 2001;
    background-color: #fff;
    overflow: auto;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  .sidebar.active {
    right: 0;
  }
  .sidebar .sidebar-title {
    margin: 0 -15px;
    height: 53px;
    text-align: center;
    border-bottom: 1px solid #f2f2f2;
    letter-spacing: 0.5px;
    font-weight: 700;
    line-height: 55px;
    font-family: "Raleway", sans-serif;
    margin-bottom: 30px;
  }
  .trending-tags {
    margin-bottom: 20px;
  }
  .search-toggle {
    display: none;
  }
  nav.menu {
    height: 55px;
  }
  nav.menu .mobile-toggle {
    display: inline-block;
  }
  nav.menu ul.nav-list {
    position: fixed;
    width: 320px;
    height: 100%;
    top: 0;
    right: -320px;
    background-color: #fff;
    z-index: 3000;
    overflow: auto;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  nav.menu ul.nav-list.active {
    right: 0;
  }
  nav.menu ul.nav-list li {
    display: block;
    float: none;
    position: static;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  nav.menu ul.nav-list > li:first-child {
    margin-left: 0;
    text-align: center;
    position: relative;
  }
  nav.menu ul.nav-list > li:first-child .back {
    z-index: 9;
    position: absolute;
    height: 100%;
    width: 50px;
    line-height: 55px;
    font-size: 20px;
  }
  nav.menu ul.nav-list > li:first-child a {
    border-bottom: 1px solid #f2f2f2;
  }
  nav.menu ul.nav-list > li:first-child a:after {
    display: none;
  }
  nav.menu ul.nav-list > li > a {
    display: block;
  }
  nav.menu ul.nav-list > li > a .badge {
    right: 10px;
    top: 5px;
  }
  nav.menu ul.nav-list > li .dropdown-menu {
    box-shadow: none;
    position: absolute;
    top: 57px;
    left: initial;
    right: -320px;
    height: calc(100% - 55px);
    height: -webkit-calc(100% - 55px);
    height: -moz-calc(100% - 55px);
    height: -o-calc(100% - 55px);
    width: 320px;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    visibility: visible;
    display: none;
    opacity: 1;
  }
  nav.menu ul.nav-list > li .dropdown-menu.active {
    right: 0;
  }
  nav.menu ul.nav-list > li .dropdown-menu > li > a {
    width: auto;
  }
  nav.menu ul.nav-list > li .dropdown-menu > li > a:hover:after {
    transform: scale(105);
    -webkit-transform: scale(105);
    -moz-transform: scale(105);
    -o-transform: scale(105);
  }
  nav.menu ul.nav-list > li .dropdown-menu > li ul {
    top: 0;
  }
  nav.menu ul.nav-list > li .dropdown-menu [class^=col-] {
    margin-bottom: 30px;
  }
  header.primary.up {
    top: -286px;
  }
  .firstbar {
    text-align: center;
  }
  .firstbar .brand {
    float: none;
  }
  .firstbar .brand img {
    float: none;
    display: inline-block;
    margin-bottom: 15px;
  }
  .firstbar .brand h2 {
    display: block;
    float: none;
    border: none;
    margin: 0;
    padding: 0;
    width: initial;
  }
  .firstbar .search {
    margin: 0 70px;
  }
  .firstbar .right {
    float: none;
    display: block;
    margin: 10px auto;
  }
}
@media screen and (max-width: 425px) {
  .article-list figure {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
  }
  .article-list .details {
    margin-left: 0;
  }
  .article-list .details .detail {
    display: inline-flex;
    display: -webkit-inline-box;
  }
  .line div {
    font-size: 14px;
  }
  .featured {
    height: 200px;
  }
  .featured .details h1 {
    font-size: 16px;
  }
  .featured .details .category a {
    padding: 3px 6px;
  }
  .firstbar .search {
    margin-left: 20px;
    margin-right: 20px;
  }
  .topbar .topbar-nav li a i, .topbar ul.info li a i {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    display: block;
    font-size: 22px;
  }
  .topbar .topbar-nav li a span, .topbar ul.info li a span {
    display: none;
  }
  .for-mobile {
    display: block !important;
  }
  .nav-icons {
    display: none;
  }
  .box-wrapper {
    width: 100%;
  }
  .box .box-body {
    padding: 20px;
  }
}
@media screen and (max-width: 320px) {
  nav.menu ul.nav-list {
    width: 290px;
    right: -350px;
  }
  nav.menu ul.nav-list > li .dropdown-menu {
    width: 290px;
    right: -350px;
  }
  .sidebar {
    width: 290px;
    right: -350px;
  }
  article figure {
    height: 200px;
  }
}
footer.footer {
  background-color: #252e38;
  color: #848f9a;
  padding: 20px 0;
  padding-top: 50px;
  border-top: 5px solid #F73F52;
}
footer.footer a {
  color: #848f9a;
}
footer.footer .social a {
  color: #fff;
}
footer.footer .line {
  background-color: #292929;
  height: 1px;
  margin: 30px 0;
}
footer.footer .block .block-title {
  font-size: 16px;
  position: relative;
  margin: 0;
  color: #fff;
}
footer.footer .block .block-title .right {
  float: right;
  font-size: 14px;
}
footer.footer .block .block-title .right a {
  color: #ddd;
}
footer.footer .block .block-title:after {
  content: " ";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 15px;
  height: 2px;
  background-color: #F73F52;
}
footer.footer .block .block-body {
  margin-top: 30px;
}
footer.footer .block .block-body.no-margin {
  margin: 0;
}
footer.footer .footer-nav-horizontal {
  padding: 0;
  margin: 0;
}
footer.footer .footer-nav-horizontal li {
  display: inline-block;
}
footer.footer .footer-nav-horizontal li a {
  display: block;
  margin: 0 10px;
  margin-bottom: 10px;
  font-size: 12px;
  text-decoration: none;
  float: left;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
footer.footer .footer-nav-horizontal li a:hover {
  color: #F73F52;
}
footer.footer .footer-nav-horizontal li:first-child a {
  margin-left: 0;
}
footer.footer .footer-nav-horizontal li:not(:last-child):after {
  content: "/";
  float: left;
  line-height: 14px;
}
footer.footer .footer-nav {
  padding: 0;
  margin: 0;
  padding-left: 17px;
}
footer.footer .footer-nav li {
  list-style: square;
}
footer.footer .footer-nav li a {
  padding: 3px;
  display: inline-block;
  font-size: 14px;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}
footer.footer .footer-nav li a:hover {
  color: #F73F52;
}
footer.footer .foot-logo {
  width: 140px;
  margin-bottom: 20px;
}
footer.footer .foot-logo img {
  width: 100%;
}
footer.footer .brand-description {
  line-height: 24px;
}
footer.footer .newsletter {
  background-color: transparent;
  border: none;
  padding: 0;
}
footer.footer .newsletter .btn {
  margin-top: 10px;
  border-radius: 3px;
}
footer.footer .newsletter .input-group {
  position: relative;
  width: 100%;
}
footer.footer .newsletter .input-group-text {
  border-radius: 0;
}
footer.footer .newsletter .input-group-addon {
  background-color: transparent;
  border-color: transparent;
  position: absolute;
  left: 2px;
  top: 0;
  z-index: 9;
  height: 40px;
  line-height: 26px;
}
footer.footer .newsletter .input-group-addon i:before {
  transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -o-transform: scale(1.4);
}
footer.footer .article-mini .detail .time, footer.footer .article-mini .detail .category a {
  color: #848f9a;
}
footer.footer .tags {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
footer.footer .tags li {
  display: inline-block;
}
footer.footer .tags li a {
  padding: 7px 12px;
  color: #848f9a;
  border: 1px solid #848f9a;
  margin: 0 5px;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 5px;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  font-size: 14px;
}
footer.footer .tags li a:first-child {
  margin-left: 0;
}
footer.footer .tags li a:hover {
  border-color: #F73F52;
  color: #fff;
}
footer.footer .copyright {
  text-align: center;
  margin-top: 70px;
  color: #848f9a;
  border-top: 1px solid #222;
  padding-top: 35px;
  padding-bottom: 20px;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
}
footer.footer .copyright a {
  text-decoration: underline;
}
footer.footer .copyright div {
  margin-top: 7px;
}
footer.footer .copyright div i {
  color: #E74C3C;
  padding: 0 3px;
}
footer.footer .article-mini {
  margin-bottom: 23px;
}
footer.footer .article-mini .inner {
  border-bottom: 0;
}
footer.footer .article-mini h1 {
  font-size: 14px;
}
footer.footer .article-mini h1 a {
  color: #ddd;
}
footer.footer .article-mini .detail .category a {
  color: #848f9a;
}
footer.footer .btn-magz {
  border-color: #fff;
  border-radius: 3px;
  font-size: 14px;
  padding-left: 13px;
  padding-right: 13px;
}

section.single .line {
  margin-top: 30px;
  margin-bottom: 30px;
}
section.single article.main-article header {
  margin: 0;
}
section.single article.main-article header h1 {
  margin: 0;
  line-height: 1.1em;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 10px;
}
section.single article.main-article header .details {
  font-size: 10px;
  color: #989898;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}
section.single article.main-article header .details li {
  position: relative;
  display: inline-block;
  float: left;
  margin-right: 30px;
}
section.single article.main-article header .details li:after {
  content: ".";
  position: absolute;
  font-size: 30px;
  top: -2px;
  margin-left: 10px;
  line-height: 0;
}
section.single article.main-article header .details li a {
  color: #F73F52;
}
section.single article.main-article header .details li:last-child:after {
  content: "";
}
section.single article.main-article .main {
  padding-top: 30px;
  margin-top: 20px;
}
section.single article.main-article .main a {
  color: #F73F52;
}
section.single article.main-article .main p, section.single article.main-article .main ul {
  font-size: 16px;
  color: #181818;
  margin-bottom: 20px;
  line-height: 30px;
}
section.single article.main-article .main .featured {
  height: auto;
  position: relative;
  overflow: initial;
  margin-bottom: 0;
}
section.single article.main-article .main .featured figure {
  position: relative;
  height: auto;
}
section.single article.main-article .main .featured figure figcaption {
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
  color: #686868;
}
section.single article.main-article .main h1, section.single article.main-article .main h2, section.single article.main-article .main h3, section.single article.main-article .main h4, section.single article.main-article .main h5, section.single article.main-article .main h6 {
  line-height: 32px;
}
section.single footer {
  margin-top: 30px;
  text-align: left;
  display: flex;
  display: -webkit-box;
  flex-wrap: wrap;
}
section.single footer .col-m:first-child {
  width: 60%;
}
section.single footer .col-m:last-child {
  width: 40%;
  text-align: right;
}
section.single footer .col-m:last-child .love {
  float: none;
  border: 2px solid #989898;
  border-radius: 30px;
  display: inline-block;
  padding: 3px 12px;
}
section.single footer .col-m:last-child .love i {
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
}
section.single footer .col-m:last-child .love i.animated {
  top: 3px;
  left: 12px;
}
section.single footer .col-m:last-child .love.active {
  background-color: #F73F52;
  color: #fff;
  border-color: #F73F52;
}
section.single footer h1 {
  font-size: 18px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
}
section.single footer h1 i {
  margin-right: 5px;
}
section.single footer .tags {
  margin: 0;
  padding: 0;
}
section.single footer .tags li {
  float: left;
  display: block;
}
section.single footer .tags li a {
  display: block;
  margin-bottom: 5px;
  padding: 3px 7px;
  border: 1px solid #989898;
  margin-right: 5px;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
}
section.single footer .tags li a:hover {
  border-color: #000;
  color: #F73F52;
}
section.single article.related .inner {
  box-shadow: none;
}
section.single article.related .inner .padding {
  padding: 0;
}
section.single article.related .detail .time {
  color: #989898;
}
section.single article.related .detail .category {
  margin-left: 0;
}

blockquote {
  font-style: italic;
  font-weight: 300;
}

.sharing {
  display: inline-block;
  width: auto;
  padding-bottom: 20px;
  margin-top: 20px;
}
.sharing .title {
  font-size: 12px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}
.sharing .title i {
  margin-right: 5px;
}
.sharing ul li {
  float: left;
}
.sharing ul li.count {
  text-align: center;
  font-size: 30px;
  color: #F73F52;
  font-weight: 700;
  margin-left: 10px;
  display: inline-block;
  float: left;
  margin-top: -10px;
}
.sharing ul li.count div {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 300;
  color: #000;
  margin-top: -5px;
  display: block;
}
.sharing ul li a {
  text-decoration: none;
  width: auto;
  height: auto;
  padding-right: 25px;
  padding-left: 25px;
}
.sharing ul li a i {
  margin-right: 10px;
}
.sharing ul li a:hover {
  color: #fff !important;
  opacity: 0.8;
}

.author {
  display: inline-block;
  width: 100%;
}
.author figure {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0;
  float: left;
}
.author figure img {
  width: 100%;
}
.author .details {
  margin-left: 120px;
}
.author .details .job {
  font-size: 12px;
  color: #898989;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.author .details .name {
  margin: 0;
  margin-top: 5px;
}
.author .details p {
  margin-top: 10px;
  font-size: 15px;
  line-height: 26px;
}
.author .details .social li a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.author .details .social li a i:before {
  font-size: 12px;
}

.comments .title {
  font-weight: 400;
  margin-bottom: 20px;
}
.comments .title a {
  font-size: 10px;
  display: inline-block;
  margin-left: 10px;
  font-weight: 600;
  letter-spacing: 1px;
}
.comments .comment-list {
  margin-top: 10px;
}
.comments .comment-list .item {
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
}
.comments .comment-list .item .user {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}
.comments .comment-list .item .user figure {
  width: 65px;
  height: 65px;
  overflow: hidden;
  border-radius: 50%;
  float: left;
  display: inline-block;
}
.comments .comment-list .item .user figure img {
  width: 100%;
}
.comments .comment-list .item .user .details {
  margin-left: 85px;
}
.comments .comment-list .item .user .details .name {
  margin: 0;
  margin-bottom: 3px;
  margin-top: 15px;
  font-weight: 700;
}
.comments .comment-list .item .user .details .time {
  color: #999;
}
.comments .comment-list .item .user .details .description {
  font-size: 15px;
  margin-top: 20px;
  line-height: 26px;
}
.comments .comment-list .item .reply-list {
  padding-left: 50px;
}
.comments .comment-list .item .reply-list .item {
  border: none;
}
.comments .comment-list.sm .item {
  border: none;
  padding: 0 0 5px 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #f2f2f2;
}
.comments .comment-list.sm .item:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.comments .comment-list.sm .item .user figure {
  width: 45px;
  height: 45px;
}
.comments .comment-list.sm .item .user .details {
  margin-left: 60px;
}
.comments .comment-list.sm .item .user .details .name {
  margin-top: 5px;
  font-size: 18px;
}
.comments .comment-list.sm .item .user .details .time {
  font-size: 12px;
}
.comments .comment-list.sm .item .user .details .description {
  margin-top: 10px;
  font-size: 14px;
  line-height: 24px;
}
.comments .form-control:focus {
  border-color: #484848;
}
.comments textarea.form-control {
  height: 180px;
  resize: none;
}
.comments .btn {
  padding-left: 25px;
  padding-right: 25px;
}
.comments form {
  margin-top: 30px;
}

.page {
  text-align: center;
}
.page .breadcrumb {
  margin-top: 20px;
}
.page .page-title {
  margin: 0;
  margin-top: 10px;
}
.page .page-subtitle {
  font-weight: 300;
  font-size: 12px;
  margin: 0;
  margin-top: 5px;
  font-family: "Lato";
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.page .line {
  margin-top: 60px;
  margin-bottom: 40px;
}
.page .page-description {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: #484848;
  margin-top: 20px;
  text-align: left;
}
.page .page-description h1 {
  color: #000;
  margin-bottom: 7px;
}
.page .page-description p {
  margin-bottom: 20px;
}
.page .question {
  font-weight: 700;
  text-align: center;
}
.page .question a {
  margin-left: 10px;
}
.page form.contact {
  margin-top: 30px;
}
.page form.contact .form-group textarea {
  height: 200px;
}
.page .element-block .element-title {
  margin-bottom: 30px;
  position: relative;
}
.page .element-block .element-title :first-child {
  margin-top: 0;
}
.page .element-block .element-title:after {
  content: " ";
  width: 15px;
  height: 2px;
  background-color: #F73F52;
  bottom: -5px;
  left: 0;
  position: absolute;
}
.page .element-block .element-body {
  display: inline-block;
  width: 100%;
}
.page .element-block .element-body .code-block {
  margin-top: 20px;
}
.page .element-block .element-body .code-block .code-title {
  margin-bottom: 5px;
  font-weight: 500;
}
.page .element-block .colors {
  margin: 0;
  padding: 0;
}
.page .element-block .colors li {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin-right: 10px;
  margin-bottom: 40px;
}
.page .element-block .colors li.primary {
  background-color: #F73F52;
}
.page .element-block .colors li.secondary {
  background-color: #C8081C;
}
.page .element-block .colors li.success {
  background-color: #1ABC9C;
}
.page .element-block .colors li.warning {
  background-color: #F39C12;
}
.page .element-block .colors li.info {
  background-color: #3498db;
}
.page .element-block .colors li.danger {
  background-color: #E74C3C;
}
.page .element-block .colors li .details h1 {
  margin: 0;
  font-size: 14px;
  margin-top: 87px;
  text-align: center;
  font-weight: 600;
}

.carousel-1 .owl-dots .owl-dot span {
  margin: 5px;
}
.carousel-1 .owl-dots .owl-dot.active span {
  background-color: #F73F52;
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -o-transform: scale(1.3);
}
.carousel-1 .owl-nav {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  opacity: 0;
}
.carousel-1 .owl-nav .owl-prev, .carousel-1 .owl-nav .owl-next {
  margin-top: 10px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 26px;
  line-height: 42px;
  text-align: center;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  opacity: 0.7;
}
.carousel-1 .owl-nav .owl-prev:hover, .carousel-1 .owl-nav .owl-next:hover {
  background-color: #F73F52;
}
.carousel-1 .owl-nav .owl-prev {
  left: 10px;
}
.carousel-1 .owl-nav .owl-next {
  right: 10px;
}
.carousel-1:hover .owl-nav {
  opacity: 1;
}

.video-list {
  padding: 0;
  margin: 0;
}
.video-list li {
  display: block;
  margin-bottom: 20px;
}
.video-list li a figure {
  height: 200px;
  background-color: #f2f2f2;
  position: relative;
  overflow: hidden;
}
.video-list li a figure .duration {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #212121;
  color: #fff;
  padding: 3px 7px;
  font-size: 12px;
}
.video-list li a figure .play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  line-height: 105px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  color: #000;
  text-align: center;
}
.video-list li a figure .play i {
  font-size: 30px;
}
.video-list li a .desc {
  margin-top: 10px;
}
.video-list li a .desc .title {
  display: inline-block;
  font-size: 18px;
  text-decoration: none;
  margin: 0;
}
.video-list li a .desc .title.loading {
  background-color: #f2f2f2;
  width: 200px;
  height: 20px;
}
.video-list li a .desc .author {
  display: block;
  color: #999;
  margin-top: 3px;
}
.video-list li a .desc .author.loading {
  background-color: #f2f2f2;
  width: 100px;
  height: 12px;
}

.checkbox-group {
  margin-bottom: 5px;
}
.checkbox-group .group-title {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 15px;
  display: inline-block;
}
.checkbox-group [class^=icheckbox], .checkbox-group [class^=iradio] {
  margin-right: 5px;
}

.nav-tabs-group {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #ddd;
}
.nav-tabs-group .nav-tabs-list {
  padding: 0;
  margin: 0;
}
.nav-tabs-group .nav-tabs-list li {
  float: left;
  display: block;
}
.nav-tabs-group .nav-tabs-list li a {
  display: block;
  padding: 10px 20px;
  color: #999;
  font-weight: 400;
  text-decoration: none;
}
.nav-tabs-group .nav-tabs-list li a:hover {
  color: #191E21;
}
.nav-tabs-group .nav-tabs-list li.active a {
  color: #191E21;
  border-bottom: 2px solid #F73F52;
}
.nav-tabs-group .form-control {
  height: 30px;
  padding: 0;
  margin-top: 3px;
}
.nav-tabs-group .nav-tabs-right {
  float: right;
  display: inline-block;
  width: 100px;
}

.featured-author {
  display: block;
}
.featured-author .featured-author-cover {
  width: 100%;
  height: 250px;
  display: inline-block;
  background-size: cover;
  background-position: center;
  position: relative;
}
.featured-author .featured-author-cover .badges {
  position: absolute;
  top: 10px;
  left: 10px;
}
.featured-author .featured-author-cover .badges .badge-item {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 30px;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  background-color: #191E21;
  letter-spacing: 1px;
}
.featured-author .featured-author-cover .featured-author-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  text-align: center;
}
.featured-author .featured-author-cover .featured-author-picture {
  margin: 0 auto;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #fff;
}
.featured-author .featured-author-cover .featured-author-picture img {
  width: 100%;
}
.featured-author .featured-author-cover .featured-author-info .name {
  font-size: 18px;
  color: #fff;
  margin: 0;
  margin-top: 10px;
}
.featured-author .featured-author-cover .featured-author-info .desc {
  font-size: 12px;
  margin-top: 3px;
  color: #ddd;
}
.featured-author .featured-author-body {
  box-shadow: 0 0 3px #ddd;
  margin-top: -40px;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  z-index: 1;
  border-radius: 3px;
  background-color: #fff;
  line-height: 20px;
}
.featured-author .featured-author-body .featured-author-count {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
}
.featured-author .featured-author-body .featured-author-count .item {
  float: left;
  width: 33.3333333333%;
  width: -webkit-calc(100% / 3);
  width: -moz-calc(100% / 3);
  width: -o-calc(100% / 3);
  text-align: center;
  padding: 5px 0;
  border-right: 1px solid #f2f2f2;
  transition: all 0.5s;
}
.featured-author .featured-author-body .featured-author-count .item a:hover {
  text-decoration: none;
}
.featured-author .featured-author-body .featured-author-count .item a:hover .name {
  color: #C8081C;
}
.featured-author .featured-author-body .featured-author-count .item:last-child {
  border-right: 0;
}
.featured-author .featured-author-body .featured-author-count .item .name {
  text-transform: uppercase;
  font-size: 12px;
  color: #F73F52;
  font-weight: 500;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
}
.featured-author .featured-author-body .featured-author-count .item .value {
  font-size: 18px;
  font-weight: 800;
  color: #666;
}
.featured-author .featured-author-body .featured-author-count .item .icon {
  padding-top: 13px;
  margin: 0 auto;
  display: inline-block;
  font-size: 12px;
}
.featured-author .featured-author-body .featured-author-count .item .icon div {
  float: left;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
}
.featured-author .featured-author-body .featured-author-count .item .icon i {
  display: inline-block;
  float: left;
  margin-left: 5px;
}
.featured-author .featured-author-body .featured-author-quote {
  padding: 10px;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  font-style: oblique;
  color: #999;
  text-align: center;
}
.featured-author .featured-author-body .featured-author-footer {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.featured-author .featured-author-body .featured-author-footer a {
  display: block;
  padding: 5px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 12px;
  border-top: 1px solid #f2f2f2;
}
.featured-author .featured-author-body .block {
  display: inline-block;
  padding: 15px;
}
.featured-author .featured-author-body .block .block-title {
  font-size: 14px;
  position: relative;
  font-weight: 800;
  margin: 0;
  text-transform: uppercase;
}
.featured-author .featured-author-body .block .block-title .all {
  position: absolute;
  right: 0;
  font-size: 12px;
  font-weight: 700;
  color: #383838;
  top: 5px;
}
.featured-author .featured-author-body .block .block-title .all i {
  color: #F73F52;
  margin-left: 5px;
}
.featured-author .featured-author-body .block .block-body {
  margin-top: 10px;
}

.item-list-round {
  padding: 0;
  margin: 0;
}
.item-list-round li {
  float: left;
  display: block;
}
.item-list-round li.hidden {
  display: none;
}
.item-list-round li a {
  display: block;
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  background-size: cover;
  background-position: center;
  position: relative;
}
.item-list-round li a:hover {
  opacity: 0.7;
}
.item-list-round li a .more {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  line-height: 64px;
  font-size: 18px;
  font-weight: 600;
}

.aside-title .carousel-nav, .title-col .carousel-nav, .best-of-the-week .carousel-nav {
  float: right;
  display: inline-block;
}
.aside-title .carousel-nav .prev, .aside-title .carousel-nav .next, .title-col .carousel-nav .prev, .title-col .carousel-nav .next, .best-of-the-week .carousel-nav .prev, .best-of-the-week .carousel-nav .next {
  display: block;
  line-height: 24px;
  width: 25px;
  height: 25px;
  border: 2px solid #ddd;
  color: #999;
  text-align: center;
  float: left;
  cursor: pointer;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
}
.aside-title .carousel-nav .prev:hover, .aside-title .carousel-nav .next:hover, .title-col .carousel-nav .prev:hover, .title-col .carousel-nav .next:hover, .best-of-the-week .carousel-nav .prev:hover, .best-of-the-week .carousel-nav .next:hover {
  background-color: #f2f2f2;
}
.aside-title .carousel-nav .prev, .title-col .carousel-nav .prev, .best-of-the-week .carousel-nav .prev {
  border-right: 0;
}

.video_list_youtube .fancybox-caption-wrap h4 {
  color: #fff;
  font-size: 20px;
}
.video_list_youtube .fancybox-caption-wrap .author {
  opacity: 0.8;
}
.video_list_youtube .fancybox-caption-wrap a {
  font-size: 12px;
}
.banner {
  position: relative;
  text-align: center;
  margin: 20px 0;
}
.banner.header {
  margin: 10px 0;
}
.banner.header:after {
  content: " ";
}
.banner img {
  max-width: 100%;
}
.banner:after {
  content: "- advertisement -";
  font-size: 12px;
  color: #999;
  letter-spacing: 0.5px;
  margin: 0 auto;
}

.mfp-wrap {
  z-index: 2002;
}

.mfp-bg {
  z-index: 2001;
}